import { Component, Input } from '@angular/core';
import { ControleType, Permissions, StrategieComptage, UniteType } from '@lexi-clients/lexi';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';

@Component({
  selector: 'app-bon-detail-parametres',
  templateUrl: './bon-detail-parametres.component.html',
  styleUrls: ['./bon-detail-parametres.component.scss'],
})
export class BonDetailParametresComponent {
  @Input() isModificationEnCours = false;
  @Input() strategieComptage: StrategieComptage = this.getDefaultStrategieComtpage();

  uniteTypeDataSource = [
    { id: UniteType.base, intitule: "Base" },
    { id: UniteType.achat, intitule: "Achat" },
    { id: UniteType.vente, intitule: "Vente" },
  ];

  controleTypeDataSource = [
    { id: ControleType.aucun, intitule: "Aucun" },
    { id: ControleType.alerter, intitule: "Alerter" },
    { id: ControleType.interdire, intitule: "Interdire" },
  ];

  canGererBons: boolean = false;

  constructor(private readonly authService: AuthService) {
    this.canGererBons = this.authService.isGrantedWith(Permissions.canGererBons);
  }

  private getDefaultStrategieComtpage(): StrategieComptage {
    return {
      actifNumera: false,
      optionsNumera: {
        afficheNavigationCarrousel: false,
        retourAutomatiqueListeArticle: false,
        afficherChampsCommentaire: false,
        afficherChampsZone: false,
        afficherChampsQuantiteRestante: false,
        afficherCommandeForcer: false,
        avertirSiteDocumentDifferent: false,
        activerControleReferenceArticle: true,
        focusQuantiteApresScan: false,
        zoneObligatoire: false,
      },
      afficherArticlesPrevus: true,
      afficherQuantitesPrevues: false,
      saisieNumeroSerie: false,
      saisieNumeroColis: false,
      saisieDlc: false,
      ajoutArticle: ControleType.aucun,
      uniteParDefaut: UniteType.base,
      envoyerQuantitesNulles: false,
      autoriserComptageTerminal: false,
    };
  }
}
