<!-- Filtres -->
<div *ngIf="autoriserFiltreGlobal" style="display: flex; align-items: center; margin-bottom: 12px; flex-wrap: wrap;">
  <!-- Filtre par ClassificationType -->
  <dx-select-box [dataSource]="classificationTypes"
    [width]="280"
    [displayExpr]="classificationTypeDisplayExpr"
    valueExpr="id"
    [showClearButton]="true"
    (onValueChanged)="onClassificationTypeChanged()"
    [(value)]="selectedClassificationTypeId"
    style="margin: 0 12px 12px 0;"
    label="Type de classification"
  ></dx-select-box>

  <!-- Filtre par type de prix -->
  <dx-select-box [dataSource]="prixCustomStore"
    displayExpr="libelleLong"
    valueExpr="id"
    [showClearButton]="true"
    (onValueChanged)="onPrixChanged()"
    [(value)]="selectedPrixId"
    style="margin: 0 12px 12px 0;"
    label="Type de prix"
  ></dx-select-box>

  <!-- Recherche global -->
  <dx-text-box
    #textBox
    [(value)]="rechercheGlobale"
    (onEnterKey)="setArticleDataSource()"
    style="margin: 0 12px 12px 0;"
    label="Recherche globale"
  ></dx-text-box>

  <!-- Bouton Rechercher -->
  <dx-button icon="find" text="Rechercher" (onClick)="setArticleDataSource()" style="margin-bottom: 12px;"></dx-button>
</div>

<dx-data-grid
  #dataGrid
  id="article-list-datagrid-main-content"
  [dataSource]="articleDataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onSelectionChanged)="onSelectionChanged($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item location="center" *ngIf="enableRowDragging">
      <div *dxTemplate style="display: flex; align-items: center;">
        <dx-switch #sansClassificationSwitchRef
          [(value)]="sansClassificationValue"
          (onValueChanged)="onSansClassificationChanged($event)"
          [elementAttr]="{class: 'mr-12'}"
        ></dx-switch>
        <span>Sans classification uniquement</span>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        {{ getTexteLignesSelectionees() }}
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <app-objecttype-edition-possible-list [objectType]="objectType.article" [objectIds]="this.selectedArticlesIds" [isDropDownButton]="true"></app-objecttype-edition-possible-list>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearDataGridFilters()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setArticleDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-state-storing [enabled]="true" [storageKey]="grilleStorageKey" appStorage></dxo-state-storing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
  <dxo-export [enabled]="true" fileName="articles"></dxo-export>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-selection [mode]="typeSelection"></dxo-selection>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true" [grouping]="true" [groupPaging]="true"></dxo-remote-operations>
  <dxo-row-dragging [allowReordering]="enableRowDragging" [showDragIcons]="enableRowDragging" group="articleList"></dxo-row-dragging>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code" [allowHeaderFiltering]="false" [allowGrouping]="false" cellTemplate="cellTemplate">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/article/{{data.data.id}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="libelleLong" caption="Libellé" [allowHeaderFiltering]="false" [allowGrouping]="false"></dxi-column>
  <dxi-column dataField="type" caption="Type" [width]="104">
    <dxo-lookup [dataSource]="articleTypeDataSource" valueExpr="id" displayExpr="intitule"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="stockSociete" caption="Stock (société)" [width]="110" [allowHeaderFiltering]="false" [allowFiltering]="false" *ngIf="showStockSociete && sourceStockageId == null" [showInColumnChooser]="showStockSociete" format="fixedPoint"></dxi-column>
  <dxi-column dataField="stockSite" caption="Stock ({{'site' | internationalisation}})" [width]="100" [allowHeaderFiltering]="false" [allowFiltering]="false" *ngIf="showStockSite && sourceStockageId == null" [showInColumnChooser]="showStockSite" format="fixedPoint"></dxi-column>
  <dxi-column caption="Stock par site" cellTemplate="stockTemplate" [width]="105" alignment="center">
    <div *dxTemplate="let cellInfo of 'stockTemplate'">
      <dx-button icon="menu" type="edit" (onClick)="showStockParSite(cellInfo.data)"></dx-button>
    </div>
  </dxi-column>

  <dxi-column
    caption="Disp. Source"
    [calculateCellValue]="calculateStockDisponibleSource"
    format="fixedPoint"
    *ngIf="showStockSociete && sourceStockageId != null"
    headerCellTemplate="dispoSourceHeaderTemplate">
      <div *dxTemplate="let data of 'dispoSourceHeaderTemplate'">
        <span style="margin-right: 2px;">Disp. Source</span>
        <i id="stockSourceLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showStockSourceLegende = true" (mouseleave)="showStockSourceLegende = false"></i>
      </div>
  </dxi-column>

  <dxi-column
    caption="Disp. Destination"
    [calculateCellValue]="calculateStockDisponibleDestination"
    format="fixedPoint"
    *ngIf="showStockSociete && destinationStockageId != null"
    headerCellTemplate="dispoDestinationHeaderTemplate">
      <div *dxTemplate="let data of 'dispoDestinationHeaderTemplate'">
        <span style="margin-right: 2px;">Disp. Destination</span>
        <i id="stockDestinationLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showStockDestinationLegende = true" (mouseleave)="showStockDestinationLegende = false"></i>
      </div>
  </dxi-column>

  <dxi-column dataField="tarifValeurAppliquee" caption="Prix de vente" [width]="105" [allowHeaderFiltering]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="uniteBaseIntitule" caption="Unité de base" [width]="121"> </dxi-column>
  <dxi-column dataField="classificationValueNiveauUnLibelle" caption="Classification Niv. 1" [width]="165"></dxi-column>
  <dxi-column dataField="classificationValueNiveauDeuxLibelle" caption="Classification Niv. 2" [width]="165"> </dxi-column>

  <!-- Colonnes cachées -->
  <dxi-column dataField="classificationValueNiveauTroisLibelle" caption="Classification Niv. 3" [width]="165" [visible]="false"> </dxi-column>
  <dxi-column dataField="fournisseurPrincipalIntitule" caption="Fournisseur principal" [visible]="false"></dxi-column>
  <dxi-column dataField="taxeGroupeIntitule" caption="Groupe de taxe" [visible]="false"> </dxi-column>
  <dxi-column dataField="bloque" caption="Bloqué" [width]="98" [visible]="false"></dxi-column>

  <dxi-column dataField="stockTheoriqueSource" caption="Stock (source)" [visible]="false"></dxi-column>
  <dxi-column dataField="stockTheoriqueDestination" caption="Stock (destination)" [visible]="false"></dxi-column>
  <dxi-column dataField="quantiteReserveeSource" caption="Reservée (source)" [visible]="false"></dxi-column>
  <dxi-column dataField="quantiteReserveeDestination" caption="Reservée (destination)" [visible]="false"></dxi-column>

</dx-data-grid>

<!-- Boutons Annuler / Confirmer -->
<div id="article-list-datagrid-footer" *ngIf="showConfirmationButtons && typeSelection != 'none' && typeSelection != null">
  <dx-button type="danger" text="Annuler" (onClick)="onCancel()" style="margin-right: 6px;"></dx-button>
  <dx-button type="success" text="Confirmer la sélection" (onClick)="onConfirm()"></dx-button>
</div>

<!-- Popover: Légende Stock Source -->
<dx-popover target="#stockSourceLegendeIcon" [width]="300" [(visible)]="showStockSourceLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Stock disponible sur la source (théorique - réservé)
  </div>
</dx-popover>

<!-- Popover: Légende Stock Destination -->
<dx-popover target="#stockDestinationLegendeIcon" [width]="300" [(visible)]="showStockDestinationLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Stock disponible sur la destination (théorique - réservé)
  </div>
</dx-popover>

<!-- Popup: stock par site -->
<dx-popup
  [title]="'Stock par site - ' + selectedArticle?.libelleLong"
  [closeOnOutsideClick]="true"
  [(visible)]="showPopupStockSite"
  (onHiding)="hideStockParSitePopup()"
  [showCloseButton]="true"
>
  <app-lot-stockage-datagrid
    [currentSiteId]="currentSiteId"
    [articleId]="selectedArticle?.id"
    [dataGridMarginTop]="'calc(100vh - 200px)'"
    [prixId]="selectedPrixId"
    [canAfficherPMP]="isAffichagePMPAutorise"
  ></app-lot-stockage-datagrid>
</dx-popup>